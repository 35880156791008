.card {
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.imgBx {
  height: 300px;
  overflow: hidden;
}

.imgBx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentBx {
  padding: 20px;
  bottom: 10px;
  left: -5.6%;
  padding-bottom: 20px;
}

.contentBx .model {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.details {
  list-style: none;
  padding: 0;
  margin: 8px 0;
}

.detail_item {
  padding: 5px 0;
  color: #191212;
  background-color: #fff;
  border-bottom: 0.5px solid #191212;
}

/* .detail_item:last-child {
  border-bottom: none;
} */

.detail_item::before {
  content: "";
  margin-right: 6px;
}

.orderBtn {
  bottom: 10px;
  background: rgb(22, 166, 22);
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  border: 20px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.orderBtn:hover {
  background: #0079e1;
}

a {
  text-align: center;
  display: block;
  height: 40px;
  background-color: rgb(22, 166, 22);
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 40px;
  transition: background-color 0.2s ease;
  margin-bottom: 10px;
}

a:hover {
  background-color: #0064b7;
}

.size-buttons {
  display: flex;
}

.size-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background: #474040;
  color: #fff;
  cursor: pointer;
}

.size-btn:hover {
  background: rgb(22, 166, 22);
}

.active {
  background: rgb(22, 166, 22);
  color: #fff;
}
