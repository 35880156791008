@import url("https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,600,700,800, 800i, 900&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #282e33;
  font-family: sans-serif;
}

.container {
  position: relative;
  overflow: auto;
}

.container .card {
  position: relative;
  width: 350px;
  height: 450px;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

.container .card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 166, 22);
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.container .card:before {
  clip-path: circle(300px at 50% -20%);
}

.container .card:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  font-size: 4em;
  font-weight: 800;
  font-style: italic;
  color: #8a7b7b;
}

.container .card .imgBx {
  position: absolute;
  top: 30%;
  left: 100%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 2.5s;
}

.container .card .imgBx {
  top: -25%;
  transform: translateX(-100%);
}

.container .card .imgBx img {
  position: absolute;
  top: 27%;
  left: 20%;
  transform: translate(-50%, -50%) rotate(20deg);
  width: 200px;
  height: 200px;
}

.container .card .contentBx {
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 3s;
  z-index: 90;
}

.container .card .contentBx {
  height: 190px;
}

.container .card .contentBx h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
}

.container .card .contentBx .color {
  color: #000000;
  border: 5px;
  background-color: #ffffff;
}
.container .card .contentBx .size,
.container .card .contentBx .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.container .card .contentBx .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card .contentBx .color {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .contentBx .size h3,
.container .card .contentBx .color h3 {
  color: rgb(243, 243, 243);
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .size span {
  width: 40px;
  height: 26px;
  padding: 4px 6px;
  text-align: center;
  line-height: 26px;
  font-size: 17px;
  display: inline-block;
  margin: 0 5px;
  transition: 0.5s;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(22, 166, 22);
  color: #fff;
  font-weight: 500;
}

.container .card .contentBx .color span {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  color: #000;
  padding: 5px 8px;
  font-size: 17px;
  font-weight: 500;
}

.container .card .contentBx a {
  display: inline-block;
  padding: 10px 20px;
  color: #ffff;
  background-color: rgb(22, 166, 22);
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  font-size: 17px;
}

.container .card .contentBx a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.7s;
}

.price {
  margin-top: 5px;
  font-size: 25px;
  font-weight: 400;
}

.model {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.wheel {
  animation: spin 50s ease-in-out infinite;
}

.navbar {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-color: #f8f9fa; /* Change this to match your site's color scheme */
  padding: 10px 0px;
  z-index: 1000; /* This ensures the navbar appears above other content */
}

.navbar select {
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 5px;
  background-color: #f8f9fa; /* Change this to match your site's color scheme */
  font-size: 16px;
  color: #333; /* Change this to match your site's color scheme */
}

.navbar select:focus {
  outline: none;
}

.navbar select.category-filter {
  width: 50%;
}

.navbar select.size-filter {
  width: 50%;
}

.notFound h1 {
  text-align: center;
  font-size: 1.2em;
  color: rgb(22, 166, 22);
}
